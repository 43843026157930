// Required imports
import React from 'react'
import styled from 'styled-components'

/// //////////////////////////////////////////////////////////
// C O M P O N E N T   D E F I N I T I O N
/// //////////////////////////////////////////////////////////

const Foot = styled.footer`
  text-align: center;
  margin: 25px;
`

export default function Footer () {
  return (
    <Foot>
      © {new Date().getFullYear()}
      {` `}
      Novasloth Games LLC. All Rights Reserved.
    </Foot>
  )
}